<template>
  <div>
    <v-container fluid fill-height>
      <!-- Header -->
      <v-row>
        <v-col class="text-center mb-3">
          <h2>{{ $t('nav.account') }}</h2>
        </v-col>
      </v-row>
      <!-- User info -->
      <v-row v-if="user != null">
        <v-col class="col-md-4 offset-md-4 text-center">
          <p class="font-weight-light truncate">
            {{ user.email }}
          </p>
          <p v-if="user.nickname" class="truncate">"{{ user.nickname }}"</p>
        </v-col>
      </v-row>
      <!-- Download my data -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 text-center">
          <v-btn color="secondary" rounded depressed x-large class="px-10" @click="downloadData">{{
            $t('account.download')
          }}</v-btn>
        </v-col>
      </v-row>
      <!-- Email -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 text-center">
          <p class="font-weight-light">
            {{ $t('account.removeText') }}
            <a
              href="mailto:support@mindforcegamelab.com"
              class="white--text text-decoration-underline"
              >support@mindforcegamelab.com</a
            >
          </p>
        </v-col>
      </v-row>
      <!-- Password -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            text
            color="primaryDark"
            flat
            class="text-center px-5 pb-5"
            style="max-width: 500px; margin: auto"
          >
            <v-form v-model="isPasswordFormValid">
              <v-card-title class="justify-center">
                {{ $t('account.changePassword') }}
              </v-card-title>
              <div class="mx-5">
                <v-text-field
                  :placeholder="$t('changePassword.currentPassword')"
                  outlined
                  rounded
                  class="mgl-append-green mb-4"
                  color="secondary"
                  ref="current"
                  type="password"
                  prepend-inner-icon="mdi-lock-outline"
                  hide-details
                  v-model="currentPassword"
                  :append-icon="currentPasswordValidated ? 'mdi-check' : ''"
                  @blur="confirmCurrentPassword"
                ></v-text-field>
                <v-tooltip v-model="showTooltip" top color="secondary" allow-overflow>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :placeholder="$t('changePassword.newPassword')"
                      outlined
                      rounded
                      class="mgl-append-green mb-4"
                      color="secondary"
                      ref="newPassword"
                      type="password"
                      prepend-inner-icon="mdi-lock-outline"
                      validateOnInput
                      :rules="[
                        rules.required,
                        rules.minLength,
                        rules.uppercase,
                        rules.lowercase,
                        rules.symbol,
                        rules.number,
                        rules.writableSymbols,
                      ]"
                      required
                      hide-details
                      v-model="newPassword"
                      v-on="on"
                      :append-icon="allCriteriaFullfilled ? 'mdi-check' : ''"
                    ></v-text-field>
                  </template>
                  <ul class="mgl-pwd-list my-2">
                    <li
                      v-for="criteria in passwordCriterias"
                      :key="criteria.id"
                      class="d-flex align-center mb-1"
                    >
                      <v-chip
                        small
                        :color="criteria.fulfilled ? 'success' : 'white'"
                        :outlined="!criteria.fulfilled"
                        class="mr-2"
                        ><v-icon v-show="criteria.fulfilled" small>mdi-check</v-icon></v-chip
                      >
                      {{ criteria.text }}
                    </li>
                  </ul>
                </v-tooltip>
                <v-text-field
                  :placeholder="$t('changePassword.repeatPassword')"
                  outlined
                  rounded
                  class="mgl-append-green mb-4"
                  color="secondary"
                  name="repeatPassword"
                  ref="repeatPassword"
                  prepend-inner-icon="mdi-lock-outline"
                  type="password"
                  :rules="[rules.required, rules.matchPassword]"
                  required
                  validateOnInput
                  hide-details
                  v-model="repeatPassword"
                  v-model.trim="repeatPassword"
                  :append-icon="passwordMatch ? 'mdi-check' : ''"
                ></v-text-field>
                <v-btn
                  rounded
                  depressed
                  block
                  x-large
                  color="secondary"
                  class="mb-2"
                  :disabled="!isPasswordFormValid"
                  @click="changePassword"
                  >{{ $t('account.changePassword') }}</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Licenses -->
      <v-row v-if="user != null">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            v-if="!hasEvergreenLicense()"
            text
            rounded
            flat
            color="primaryDark"
            class="text-center mb-4"
            style="max-width: 500px; margin: auto"
          >
            <v-card-title class="justify-center">
              {{ $t('account.licenses') }}
            </v-card-title>
            <template v-for="(license, i) in user.licenses">
              <v-card-text :key="i"
                ><div :class="isValidLicense(license) ? 'mx-5' : 'mx-5 mgl-expired'">
                  <v-text-field
                    name="license"
                    outlined
                    rounded
                    disabled
                    color="secondary"
                    type="text"
                    hide-details
                    v-model="license.id"
                  ></v-text-field>
                  <p class="font-weight-thin mt-1 mb-0">
                    <template v-if="isValidLicense(license)">
                      {{ $t('addLicense.validUntil') }}:
                      {{ license | license('validToDate') }}
                    </template>
                    <template v-else>
                      <span class="error--text">
                        {{ $t('addLicense.expired') }}:
                        {{ license | license('expireDate') }}
                      </span>
                    </template>
                  </p>
                </div>
              </v-card-text>
            </template>
            <BetaSignupLinks v-bind:show="showSignupLinks" />
          </v-card>
        </v-col>
      </v-row>
      <!-- Add new license -->
      <v-expand-transition>
        <v-row v-show="canAddLicense">
          <v-col class="col-md-4 offset-md-4 pt-0">
            <v-card text color="primaryDark" flat class="text-center px-5 pb-5">
              <v-card-title class="justify-center">
                {{ $t('addLicense.addNew') }}
              </v-card-title>
              <p>{{ $t('addLicense.label') }}</p>
              <div class="mx-5">
                <v-text-field
                  :placeholder="$t('addLicense.header')"
                  outlined
                  rounded
                  class="mb-4"
                  color="secondary"
                  type="text"
                  ref="license"
                  :rules="[rules.licenseRequired, rules.length11, rules.format]"
                  v-model="tempLicense"
                  @input="tempLicense = tempLicense.replace(/\s+/g, '')"
                  @keydown.space.prevent=""
                  @paste="handlePaste"
                ></v-text-field>
                <v-btn
                  rounded
                  depressed
                  block
                  x-large
                  :disabled="!isFormValid"
                  color="secondary"
                  class="mb-2"
                  @click="addLicense"
                  >{{ $t('addLicense.activate') }}</v-btn
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-row class="mb-5" v-if="canAddLicense">
        <v-col>
          <a
            color="primary"
            href="mailto:support@mindforcegamelab.com"
            class="float-right white--text text-decoration-underline"
            >{{ $t('addLicense.askFor') }}</a
          >
        </v-col>
      </v-row>
      <!-- Deviation Emails -->
      <v-row v-if="isPlaytient">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            text
            rounded
            flat
            color="primaryDark"
            class="text-center mb-4"
            style="max-width: 500px; margin: auto"
          >
            <v-card-title class="justify-center">
              {{ $t('account.deviationEmails') }}
            </v-card-title>
            <p class="mx-5">
              <span style="float: left">{{ $t('account.adherenceEmails') }}</span>
              <span style="float: right">
                <toggle-button
                  :color="{ checked: '#A55DFF', unchecked: '#1C1051' }"
                  :disabled="!hasMedicationRequired"
                  :value="hasSendAdherenceEmails"
                  :height="parseInt('24')"
                  @change="changeAdherenceEmails"
                />
              </span>
            </p>
            <div class="v-card__text">{{ $t('account.adherenceEmailsText') }}</div>
            <hr style="border-color: #383c7a" />
            <p class="mx-5 pt-4">
              <span style="float: left">{{ $t('account.absenceEmails') }}</span>
              <span style="float: right">
                <toggle-button
                  :color="{ checked: '#A55DFF', unchecked: '#1C1051' }"
                  :value="hasSendAbsenceEmails"
                  :height="parseInt('24')"
                  @change="changeAbsenceEmails"
                />
              </span>
            </p>
            <div class="v-card__text">{{ $t('account.absenceEmailsText') }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { Auth } from 'aws-amplify';
import BetaSignupLinks from './components/BetaSignupLinks.vue';
import { addNewLicense } from '../utils/licenses';
import { getPastedData } from '../utils/clipboard';
import {
  fetchRsaKeysFromLocalStorage,
  deriveAesGcmKey,
  wrapKey,
  extractRsaKey,
} from '../utils/crypto';
import { ALLOWED_CHARACTERS_REGEX } from '../utils/constants';
import { displayError, setLoading } from '../utils/loading';
import { apiDispatch } from '../utils/dispatch';
import { getUser } from '../api/me';
import { setUserInfo } from '../api/users';

export default {
  name: 'Account',
  components: {
    BetaSignupLinks,
  },
  data() {
    return {
      tempLicense: '',
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
      isPasswordFormValid: false,
      isFormValid: false,
      rules: {
        required: (v) => !!v || this.$i18n.t('validation.fieldNotEmpty'),
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v,
          ) || this.$i18n.t('validation.emailInvalid'),
        minLength: (v) => v.length >= 8 || this.$i18n.t('validation.minCharacters'),
        uppercase: (v) => /[A-Z]/.test(v) || this.$i18n.t('validation.uppercase'),
        lowercase: (v) => /[a-z]/.test(v) || this.$i18n.t('validation.lowercase'),
        symbol: (v) =>
          /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v) || this.$i18n.t('validation.symbol'),
        number: (v) => /\d/.test(v) || this.$i18n.t('validation.number'),
        matchPassword: () =>
          this.newPassword === this.repeatPassword || this.$i18n.t('validation.passwordNotMatch'),
        writableSymbols: (v) =>
          !ALLOWED_CHARACTERS_REGEX.test(v) || this.$i18n.t('validation.writableSymbols'),
        licenseRequired: (v) => !!v || this.$i18n.t('validation.licenseRequired'),
        length11: () => true, // skipping validation of length, since some existing licenses have different length
        format: (v) => /^[a-zA-Z0-9%+\-_]+$/.test(v) || this.$i18n.t('validation.licenseFormat'),
      },
      showTooltip: false,
      currentPasswordValidated: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.api.me;
    },
    allowLicense() {
      const { user } = this;
      if (!user) {
        return false;
      }
      if (
        // @ts-ignore
        !user.licenses[0] ||
        (this.type === 'MGL' && process.env.VUE_APP_ALLOW_MULTIPLE_MGL_LICENSES)
      ) {
        return true;
      }
      return false;
    },
    showSignupLinks() {
      const { user } = this;
      if (!user) {
        return false;
      }
      // @ts-ignore
      return user.licenses < 1;
    },
    canAddLicense() {
      if (this.allowLicense) {
        return true;
      }
      return false;
    },
    isPlaytient() {
      const { user } = this;
      if (!user) {
        return false;
      }
      // @ts-ignore
      const { type } = user;
      return type === 'Playtient' || type === 'MGL';
    },
    passwordMatch() {
      return this.newPassword.length > 1 && this.newPassword === this.repeatPassword;
    },
    allCriteriaFullfilled() {
      // @ts-ignore
      return this.passwordCriterias.every((criteria) => criteria.fulfilled);
    },
    passwordCriterias() {
      return [
        {
          text: this.$i18n.t('signup.passwordCriterias.uppercase'),
          fulfilled: /[A-Z]/.test(this.newPassword),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.lowercase'),
          fulfilled: /[a-z]/.test(this.newPassword),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.number'),
          fulfilled: /\d/.test(this.newPassword),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.symbol'),
          fulfilled: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.newPassword),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.minLength'),
          fulfilled: this.newPassword.length >= 6,
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.writableCharacters'),
          fulfilled: !ALLOWED_CHARACTERS_REGEX.test(this.newPassword),
        },
      ];
    },
    hasSendAdherenceEmails() {
      return (
        this.$store.state.api.me.sendAdherenceEmails && this.$store.state.api.me.medicationRequired
      );
    },
    hasSendAbsenceEmails() {
      return this.$store.state.api.me.sendAbsenceEmails;
    },
    hasMedicationRequired() {
      return this.$store.state.api.me.medicationRequired;
    },
  },
  methods: {
    handlePaste(event) {
      const formattedText = getPastedData(event, false);
      if (formattedText !== null) {
        this.tempLicense = formattedText;
      }
      event.preventDefault();
    },
    isValidLicense(license) {
      return this.$options.filters.license(license, 'isValid');
    },
    hasEvergreenLicense() {
      if (!this.user) {
        return false;
      }
      return this.$store.getters['api/hasEvergreenLicense'];
    },
    async addLicense() {
      setLoading(this.$store, true);
      try {
        const { type } = this.user;
        await addNewLicense(this.$store, this.$router, this.$i18n, this.tempLicense);
        if (type === 'Team') {
          this.$store.commit('api/setUserInfo', { type: 'Playtient' });
        }
        setLoading(this.$store, false);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$refs.license.focus();
            displayError(this.$store, error.response.data);
          } else {
            displayError(this.$store, error.message);
          }
        } else {
          displayError(this.$store);
        }
      }
    },
    confirmCurrentPassword() {
      Auth.signIn(this.user.email, this.currentPassword).then(
        () => {
          this.currentPasswordValidated = true;
        },
        () => {
          this.currentPasswordValidated = false;
        },
      );
    },
    async changePassword() {
      setLoading(this.$store, true);
      try {
        let user = getUser(this.$store);
        const currentAesKeyObject = await deriveAesGcmKey(this.currentPassword, user.salt);
        const rsaKeys = await extractRsaKey(
          user.privateKey,
          user.publicKey,
          currentAesKeyObject.aesKey,
          user.iv,
        );
        const newAesKey = await deriveAesGcmKey(this.newPassword, user.salt);
        const { wrappedKey, iv } = await wrapKey(rsaKeys.privateKey, newAesKey.aesKey);
        await apiDispatch(this.$store, this.$router, 'stageRsaKey', {
          iv,
          privateKey: wrappedKey,
        });
        user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, this.currentPassword, this.newPassword);
        await apiDispatch(this.$store, this.$router, 'commitRsaKey', {
          iv,
          privateKey: wrappedKey,
        });
        setLoading(this.$store, false);
        await this.$root.$confirm(
          this.$i18n.t('changePassword.success'),
          this.$i18n.t('changePassword.loginAgain'),
          {
            asHtml: true,
            disableCancel: true,
            actionButtonI18nKey: 'generic.okay',
          },
        );
        this.$store.commit('invalid', true);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            displayError(this.$store, error.response.data);
          } else {
            displayError(this.$store, error.message);
          }
        } else {
          displayError(this.$store);
        }
      }
    },
    async downloadData() {
      setLoading(this.$store, true);
      try {
        const appData = {};
        const { me } = this.$store.state.api;
        const { licenses } = me;
        const appIds = new Set();
        licenses.forEach((val) => appIds.add(val.appId));
        const rsaKeys = await fetchRsaKeysFromLocalStorage(this.$store);
        for (const appId of appIds) {
          // eslint-disable-next-line no-await-in-loop
          appData[appId] = await apiDispatch(this.$store, this.$router, 'getDataPaginated', {
            privateKey: rsaKeys.privateKey,
            appId,
          });
        }
        const storage = await apiDispatch(this.$store, this.$router, 'getAllStorage', {
          privateKey: rsaKeys.privateKey,
        });

        const data = { ...me, storage, ...appData };
        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(data)], {
          type: 'application/json',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'my-data.json';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setLoading(this.$store, false);
      } catch (error) {
        displayError(this.$store, error.message);
      }
    },
    async changeAdherenceEmails(event) {
      await setUserInfo(this.$store, this.$router, { sendAdherenceEmails: event.value });
    },
    async changeAbsenceEmails(event) {
      await setUserInfo(this.$store, this.$router, { sendAbsenceEmails: event.value });
    },
  },
  watch: {
    tempLicense() {
      this.$nextTick(() => {
        this.isFormValid = this.$refs.license.validate();
      });
    },
  },
};
</script>
<style lang="scss">
.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
